<template>
  <Table
    :rows="reportchallenges"
    :columns="columns"
    :rowactions="rowactions"
    :isReady="isReady"
    :isLoading="isLoading"
    :pagination="reportchallengesPagination"
    :filters="filters"
    :filter="filter"
    v-model:offset="offset"
    v-model:limit="actualLimit"
    :classes="['table--challenge']"
  ></Table>
</template>

<script>
import { mapGetters } from 'vuex';
import Generator from '@/services/generator/GeneratorService';
import ModelTableMixin from '@/components/layout/table/ModelTableMixin';

export default {
  name: 'ChallengesTable',
  mixins: [
    ModelTableMixin,
  ],
  data() {
    return {
      model: 'reportchallenge',
      updateModel: true,
      columns: [{
        head: this.$t('common.challenge'),
        class: 'table__chip',
        component: 'ChallengeChip',
        componentAttrs: (challenge) => ({ challenge }),
      }, {
        head: this.$t('common.entity'),
        value: (item) => item.entity,
      }, {
        head: this.$t('common.year'),
        value: (item) => item.year,
      }, {
        head: this.$t('common.status'),
        class: 'status',
        component: 'Status',
        componentAttrs: (item) => ({ value: item.is_approved ? 'approved' : 'pending' }),
      }],
      filters: [{
        component: 'InputText',
        property: 'entity',
        componentProps: {
          label: this.$t('common.entity'),
        },
      }, {
        component: 'InputText',
        property: 'year',
        componentProps: {
          label: this.$t('common.year'),
        },
      }, {
        component: 'InputSelect',
        property: 'is_approved',
        componentProps: {
          label: this.$t('common.status'),
          options: Generator.trueFalse(this.$t('status.approved'), this.$t('status.pending')),
        },
      }],
      rowactions: [{
        label: this.$t('common.change_mark_revised'),
        class: (item) => (item.is_active ? 'approved' : 'pending'),
        function: this.changeApprovedStatus,
      }],
    };
  },
  computed: {
    ...mapGetters(['reportchallenges', 'reportchallengesPagination']),
  },
  methods: {
    changeApprovedStatus(item) {
      this.$store.dispatch('partial_update', {
        model: 'reportchallenge',
        id: item.id,
        data: {
          is_approved: !item.is_approved,
        },
      }).then((response) => {
        const idx = this.reportchallenges.findIndex((i) => i.id === response.data.id);
        this.reportchallenges[idx] = response.data;
      });
    },
  },
};
</script>
