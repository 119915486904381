<template>
  <Page :title="$t('reportchallenges.title')" :classes="['page--challenges']">

    <ChallengesTable
      :filter="filter"
      :limit="10"
    ></ChallengesTable>

  </Page>
</template>

<script>
import ChallengesTable from '@/components/challenges/ChallengesTable.vue';

export default {
  name: 'ChallengesPage',
  components: {
    ChallengesTable,
  },
  data() {
    return {
      filter: {
      },
    };
  },
};
</script>
